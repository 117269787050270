
import { lazy } from "react";
// lazy route table generated by fastify-renderer
export const routes = [
  ["/v0/guides/getting-started", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/getting-started/Getting-Started-Home.tsx"))],
["/v0/guides/getting-started", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/getting-started/Getting-Started-Home.tsx"))],
["/v0/guides/getting-started/what-is-gadget", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/getting-started/What-is-Gadget.mdx"))],
["/v0/guides/getting-started/what-is-gadget", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/getting-started/What-is-Gadget.mdx"))],
["/v0/guides/getting-started/how-to-build-a-gadget-app", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/getting-started/how-to-build-a-gadget-app.mdx"))],
["/v0/guides/getting-started/how-to-build-a-gadget-app", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/getting-started/how-to-build-a-gadget-app.mdx"))],
["/v0/guides/getting-started/quickstart", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/getting-started/Quickstart.mdx"))],
["/v0/guides/getting-started/quickstart", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/getting-started/Quickstart.mdx"))],
["/v0/guides/getting-started/how-is-gadget-different-from-x", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/getting-started/How-is-Gadget-Different-from-x.mdx"))],
["/v0/guides/getting-started/how-is-gadget-different-from-x", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/getting-started/How-is-Gadget-Different-from-x.mdx"))],
["/v0/guides/tutorials", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/Tutorials.tsx"))],
["/v0/guides/tutorials", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/Tutorials.tsx"))],
["/v0/guides/storing-files", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/Storing-Files.mdx"))],
["/v0/guides/storing-files", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/Storing-Files.mdx"))],
["/v0/guides/plugins/openai/building-ai-apps", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/Building-AI-Apps.mdx"))],
["/v0/guides/plugins/openai/building-ai-apps", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/Building-AI-Apps.mdx"))],
["/v0/guides/gadget-framework", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/Gadget-framework.mdx"))],
["/v0/guides/gadget-framework", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/Gadget-framework.mdx"))],
["/v0/guides/plugins", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/Plugins-overview.mdx"))],
["/v0/guides/plugins", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/Plugins-overview.mdx"))],
["/v0/guides/plugins/shopify", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/Shopify.mdx"))],
["/v0/guides/plugins/shopify", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/Shopify.mdx"))],
["/v0/guides/plugins/shopify/building-shopify-apps", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/building-shopify-apps-overview.mdx"))],
["/v0/guides/plugins/shopify/building-shopify-apps", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/building-shopify-apps-overview.mdx"))],
["/v0/guides/plugins/shopify/data-security", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-data-security.mdx"))],
["/v0/guides/plugins/shopify/data-security", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-data-security.mdx"))],
["/v0/guides/plugins/shopify/syncing-shopify-data", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Syncing-Shopify-Data.mdx"))],
["/v0/guides/plugins/shopify/syncing-shopify-data", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Syncing-Shopify-Data.mdx"))],
["/v0/guides/plugins/shopify/shopify-webhooks", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Working-with-shopify-webhooks.mdx"))],
["/v0/guides/plugins/shopify/shopify-webhooks", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Working-with-shopify-webhooks.mdx"))],
["/v0/guides/plugins/shopify/extensions", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-App-Extensions.mdx"))],
["/v0/guides/plugins/shopify/extensions", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-App-Extensions.mdx"))],
["/v0/guides/plugins/shopify/customer-account-ui-extensions", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-Customer-Account-UI-Extensions.mdx"))],
["/v0/guides/plugins/shopify/customer-account-ui-extensions", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-Customer-Account-UI-Extensions.mdx"))],
["/v0/guides/plugins/shopify/frontends", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-App-frontends.mdx"))],
["/v0/guides/plugins/shopify/frontends", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-App-frontends.mdx"))],
["/v0/guides/plugins/shopify/metafields-metaobjects", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-App-metafields-and-metaobjects.mdx"))],
["/v0/guides/plugins/shopify/metafields-metaobjects", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-App-metafields-and-metaobjects.mdx"))],
["/v0/guides/plugins/shopify/oauth", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-App-OAuth.mdx"))],
["/v0/guides/plugins/shopify/oauth", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-App-OAuth.mdx"))],
["/v0/guides/plugins/shopify/billing", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-App-Billing.mdx"))],
["/v0/guides/plugins/shopify/billing", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-App-Billing.mdx"))],
["/v0/guides/plugins/shopify/api-version-changelog", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-Api-Version-Changelog.mdx"))],
["/v0/guides/plugins/shopify/api-version-changelog", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-Api-Version-Changelog.mdx"))],
["/v0/guides/plugins/shopify/polaris", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-Polaris.mdx"))],
["/v0/guides/plugins/shopify/polaris", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-Polaris.mdx"))],
["/v0/guides/plugins/shopify/admin-connection", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-Admin-Connection.mdx"))],
["/v0/guides/plugins/shopify/admin-connection", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/building-shopify-apps/Shopify-Admin-Connection.mdx"))],
["/v0/guides/plugins/openai", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/OpenAI.mdx"))],
["/v0/guides/plugins/openai", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/OpenAI.mdx"))],
["/v0/guides/plugins/sentry", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/Sentry.mdx"))],
["/v0/guides/plugins/sentry", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/connections/Sentry.mdx"))],
["/v0/guides/tutorials/connecting-to-shopify", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/Connecting-to-Shopify.mdx"))],
["/v0/guides/tutorials/connecting-to-shopify", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/Connecting-to-Shopify.mdx"))],
["/v0/guides/tutorials/automated-product-tagger", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/automated-product-tagger/Automated-Product-Tagger.mdx"))],
["/v0/guides/tutorials/automated-product-tagger", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/automated-product-tagger/Automated-Product-Tagger.mdx"))],
["/v0/guides/tutorials/product-recommendation-quiz-app", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/quiz/Product-Recommendation-Quiz-App.mdx"))],
["/v0/guides/tutorials/product-recommendation-quiz-app", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/quiz/Product-Recommendation-Quiz-App.mdx"))],
["/v0/guides/tutorials/checkout-ui-extension", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/checkout-ui-extension/Checkout-UI-Extension.mdx"))],
["/v0/guides/tutorials/checkout-ui-extension", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/checkout-ui-extension/Checkout-UI-Extension.mdx"))],
["/v0/guides/tutorials/ai-product-recommender", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/ai-product-recommender/AI-Product-Recommender.mdx"))],
["/v0/guides/tutorials/ai-product-recommender", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/ai-product-recommender/AI-Product-Recommender.mdx"))],
["/v0/guides/tutorials/middleware", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/middleware/Middleware.mdx"))],
["/v0/guides/tutorials/middleware", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/middleware/Middleware.mdx"))],
["/v0/guides/tutorials/webhook-subscription", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/webhook-subscription/Webhook-Subscription.mdx"))],
["/v0/guides/tutorials/webhook-subscription", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/webhook-subscription/Webhook-Subscription.mdx"))],
["/v0/guides/tutorials/ai-screenwriter", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/ai-screenwriter/AI-Screenwriter.mdx"))],
["/v0/guides/tutorials/ai-screenwriter", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/ai-screenwriter/AI-Screenwriter.mdx"))],
["/v0/guides/tutorials/web-ai", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/Tutorials-Web-App.tsx"))],
["/v0/guides/tutorials/web-ai", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/Tutorials-Web-App.tsx"))],
["/v0/guides/tutorials/shopify", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/Tutorials-Shopify.tsx"))],
["/v0/guides/tutorials/shopify", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/tutorials/Tutorials-Shopify.tsx"))],
["/v0/guides/deployment", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/Deployment.mdx"))],
["/v0/guides/deployment", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/Deployment.mdx"))],
["/v0/guides/actions", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/actions/Actions-overview.mdx"))],
["/v0/guides/actions", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/actions/Actions-overview.mdx"))],
["/v0/guides/actions/types-of-actions", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/actions/Types-of-actions.mdx"))],
["/v0/guides/actions/types-of-actions", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/actions/Types-of-actions.mdx"))],
["/v0/guides/actions/code", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/actions/Action-code.mdx"))],
["/v0/guides/actions/code", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/actions/Action-code.mdx"))],
["/v0/guides/actions/triggers", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/actions/Triggers.mdx"))],
["/v0/guides/actions/triggers", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/actions/Triggers.mdx"))],
["/v0/guides/actions/actions-as-code-file", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/actions/Actions-as-code-file-migration.mdx"))],
["/v0/guides/actions/actions-as-code-file", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/actions/Actions-as-code-file-migration.mdx"))],
["/v0/guides/glossary", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/Glossary.mdx"))],
["/v0/guides/glossary", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/Glossary.mdx"))],
["/v0/guides/faq", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/FAQ.mdx"))],
["/v0/guides/faq", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/FAQ.mdx"))],
["/v0", lazy(() => import("/app/packages/docs/pages/Docs-home-page.tsx"))],
["/v0", lazy(() => import("/app/packages/docs/pages/Docs-home-page.tsx"))],
["/v0/guides", lazy(() => import("/app/packages/docs/pages/guides/Guides.tsx"))],
["/v0/guides", lazy(() => import("/app/packages/docs/pages/guides/Guides.tsx"))],
["/v0/guides/models", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/models/Models-overview.mdx"))],
["/v0/guides/models", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/models/Models-overview.mdx"))],
["/v0/guides/models/fields", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/models/Fields.mdx"))],
["/v0/guides/models/fields", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/models/Fields.mdx"))],
["/v0/guides/models/relationships", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/models/Relationships.mdx"))],
["/v0/guides/models/relationships", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/models/Relationships.mdx"))],
["/v0/guides/http-routes", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/http-routes/HTTP-routes-overview.mdx"))],
["/v0/guides/http-routes", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/http-routes/HTTP-routes-overview.mdx"))],
["/v0/guides/http-routes/route-structure", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/http-routes/Route-structure.mdx"))],
["/v0/guides/http-routes/route-structure", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/http-routes/Route-structure.mdx"))],
["/v0/guides/http-routes/route-configuration", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/http-routes/Route-configuration.mdx"))],
["/v0/guides/http-routes/route-configuration", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/http-routes/Route-configuration.mdx"))],
["/v0/guides/frontend", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/frontend/Frontend-overview.mdx"))],
["/v0/guides/frontend", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/frontend/Frontend-overview.mdx"))],
["/v0/guides/frontend/building-frontends", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/frontend/Building-Frontends.mdx"))],
["/v0/guides/frontend/building-frontends", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/frontend/Building-Frontends.mdx"))],
["/v0/guides/frontend/forms", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/frontend/Frontend-Forms.mdx"))],
["/v0/guides/frontend/forms", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/frontend/Frontend-Forms.mdx"))],
["/v0/guides/frontend/realtime-queries", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/frontend/Realtime-Queries.mdx"))],
["/v0/guides/frontend/realtime-queries", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/frontend/Realtime-Queries.mdx"))],
["/v0/guides/frontend/external-frontends", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/frontend/External-Frontends.mdx"))],
["/v0/guides/frontend/external-frontends", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/frontend/External-Frontends.mdx"))],
["/v0/guides/frontend/optimize-lcp", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/frontend/Optimize-LCP.mdx"))],
["/v0/guides/frontend/optimize-lcp", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/frontend/Optimize-LCP.mdx"))],
["/v0/guides/development-tools", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/development-tools/Tools-overview.mdx"))],
["/v0/guides/development-tools", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/development-tools/Tools-overview.mdx"))],
["/v0/guides/development-tools/logger", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/development-tools/Logger.mdx"))],
["/v0/guides/development-tools/logger", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/development-tools/Logger.mdx"))],
["/v0/guides/development-tools/cli", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/development-tools/Cli.mdx"))],
["/v0/guides/development-tools/cli", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/development-tools/Cli.mdx"))],
["/v0/guides/development-tools/environment-variables", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/development-tools/Environment-variables.mdx"))],
["/v0/guides/development-tools/environment-variables", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/development-tools/Environment-variables.mdx"))],
["/v0/guides/development-tools/keyboard-shortcuts", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/development-tools/Keyboard-shortcuts.mdx"))],
["/v0/guides/development-tools/keyboard-shortcuts", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/development-tools/Keyboard-shortcuts.mdx"))],
["/v0/guides/development-tools/access-control", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/development-tools/Access-Control.mdx"))],
["/v0/guides/development-tools/access-control", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/development-tools/Access-Control.mdx"))],
["/v0/guides/development-tools/unit-testing", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/development-tools/Unit-Testing.mdx"))],
["/v0/guides/development-tools/unit-testing", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/development-tools/Unit-Testing.mdx"))],
["/v0/guides/data-access", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/data-access/data-access-overview.mdx"))],
["/v0/guides/data-access", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/data-access/data-access-overview.mdx"))],
["/v0/guides/data-access/api", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/data-access/API-access-to-data.mdx"))],
["/v0/guides/data-access/api", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/data-access/API-access-to-data.mdx"))],
["/v0/guides/data-access/computed-fields", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/data-access/Computed-fields.mdx"))],
["/v0/guides/data-access/computed-fields", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/data-access/Computed-fields.mdx"))],
["/v0/guides/data-access/gelly", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/data-access/Gelly.mdx"))],
["/v0/guides/data-access/gelly", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/data-access/Gelly.mdx"))],
["/v0/guides/account-and-billing", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/Account-and-billing.mdx"))],
["/v0/guides/account-and-billing", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/Account-and-billing.mdx"))],
["/v0/guides/plugins/authentication", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/authentication/Auth-overview.mdx"))],
["/v0/guides/plugins/authentication", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/authentication/Auth-overview.mdx"))],
["/v0/guides/plugins/authentication/helpers", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/authentication/Helpers.mdx"))],
["/v0/guides/plugins/authentication/helpers", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/authentication/Helpers.mdx"))],
["/v0/guides/plugins/authentication/google-oauth", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/authentication/Building-with-Google-Oauth.mdx"))],
["/v0/guides/plugins/authentication/google-oauth", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/authentication/Building-with-Google-Oauth.mdx"))],
["/v0/guides/plugins/authentication/workflows", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/authentication/Auth-flows.mdx"))],
["/v0/guides/plugins/authentication/workflows", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/authentication/Auth-flows.mdx"))],
["/v0/guides/plugins/authentication/email-pass", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/authentication/Building-with-email-pass.mdx"))],
["/v0/guides/plugins/authentication/email-pass", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/authentication/Building-with-email-pass.mdx"))],
["/v0/guides/plugins/authentication/remove-default-auth-methods", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/authentication/Removing-default-auth-methods.mdx"))],
["/v0/guides/plugins/authentication/remove-default-auth-methods", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/authentication/Removing-default-auth-methods.mdx"))],
["/v0/guides/gadget-framework/v1-migration", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/v1-migration.mdx"))],
["/v0/guides/gadget-framework/v1-migration", lazy(() => import("/app/packages/docs/pages/guides/2-actions-as-code/v1-migration.mdx"))],
["/assistant", lazy(() => import("/app/packages/docs/pages/Assistant.tsx"))],
["/assistant", lazy(() => import("/app/packages/docs/pages/Assistant.tsx"))],
["/", lazy(() => import("/app/packages/docs/pages/Docs-home-page.tsx"))],
["/", lazy(() => import("/app/packages/docs/pages/Docs-home-page.tsx"))],
["/guides", lazy(() => import("/app/packages/docs/pages/guides/Guides.tsx"))],
["/guides", lazy(() => import("/app/packages/docs/pages/guides/Guides.tsx"))],
["/guides/getting-started", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/getting-started/Getting-Started-Home.tsx"))],
["/guides/getting-started", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/getting-started/Getting-Started-Home.tsx"))],
["/guides/getting-started/what-is-gadget", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/getting-started/What-is-Gadget.mdx"))],
["/guides/getting-started/what-is-gadget", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/getting-started/What-is-Gadget.mdx"))],
["/guides/getting-started/how-to-build-a-gadget-app", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/getting-started/how-to-build-a-gadget-app.mdx"))],
["/guides/getting-started/how-to-build-a-gadget-app", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/getting-started/how-to-build-a-gadget-app.mdx"))],
["/guides/getting-started/quickstart", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/getting-started/Quickstart.mdx"))],
["/guides/getting-started/quickstart", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/getting-started/Quickstart.mdx"))],
["/guides/getting-started/connection-quickstarts", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/getting-started/Connection-Quickstarts.tsx"))],
["/guides/getting-started/connection-quickstarts", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/getting-started/Connection-Quickstarts.tsx"))],
["/guides/getting-started/shopify-quickstart", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/getting-started/Shopify-quickstart.mdx"))],
["/guides/getting-started/shopify-quickstart", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/getting-started/Shopify-quickstart.mdx"))],
["/guides/getting-started/bigcommerce-quickstart", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/bigcommerce/BigCommerce-Quickstart.mdx"))],
["/guides/getting-started/bigcommerce-quickstart", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/bigcommerce/BigCommerce-Quickstart.mdx"))],
["/guides/getting-started/how-is-gadget-different-from-x", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/getting-started/How-is-Gadget-Different-from-x.mdx"))],
["/guides/getting-started/how-is-gadget-different-from-x", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/getting-started/How-is-Gadget-Different-from-x.mdx"))],
["/guides/tutorials", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/Tutorials.tsx"))],
["/guides/tutorials", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/Tutorials.tsx"))],
["/guides/storing-files", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/Storing-Files.mdx"))],
["/guides/storing-files", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/Storing-Files.mdx"))],
["/guides/plugins/openai/building-ai-apps", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/Building-AI-Apps.mdx"))],
["/guides/plugins/openai/building-ai-apps", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/Building-AI-Apps.mdx"))],
["/guides/gadget-framework", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/Gadget-framework.mdx"))],
["/guides/gadget-framework", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/Gadget-framework.mdx"))],
["/guides/plugins", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/Plugins-overview.mdx"))],
["/guides/plugins", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/Plugins-overview.mdx"))],
["/guides/plugins/shopify", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/Shopify.mdx"))],
["/guides/plugins/shopify", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/Shopify.mdx"))],
["/guides/plugins/shopify/building-shopify-apps", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/building-shopify-apps-overview.mdx"))],
["/guides/plugins/shopify/building-shopify-apps", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/building-shopify-apps-overview.mdx"))],
["/guides/plugins/shopify/data-security", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-data-security.mdx"))],
["/guides/plugins/shopify/data-security", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-data-security.mdx"))],
["/guides/plugins/shopify/syncing-shopify-data", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Syncing-Shopify-Data.mdx"))],
["/guides/plugins/shopify/syncing-shopify-data", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Syncing-Shopify-Data.mdx"))],
["/guides/plugins/shopify/shopify-webhooks", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Working-with-shopify-webhooks.mdx"))],
["/guides/plugins/shopify/shopify-webhooks", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Working-with-shopify-webhooks.mdx"))],
["/guides/plugins/shopify/extensions", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-App-Extensions.mdx"))],
["/guides/plugins/shopify/extensions", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-App-Extensions.mdx"))],
["/guides/plugins/shopify/customer-account-ui-extensions", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-Customer-Account-UI-Extensions.mdx"))],
["/guides/plugins/shopify/customer-account-ui-extensions", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-Customer-Account-UI-Extensions.mdx"))],
["/guides/plugins/shopify/frontends", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-App-frontends.mdx"))],
["/guides/plugins/shopify/frontends", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-App-frontends.mdx"))],
["/guides/plugins/shopify/metafields-metaobjects", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-App-metafields-and-metaobjects.mdx"))],
["/guides/plugins/shopify/metafields-metaobjects", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-App-metafields-and-metaobjects.mdx"))],
["/guides/plugins/shopify/oauth", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-App-OAuth.mdx"))],
["/guides/plugins/shopify/oauth", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-App-OAuth.mdx"))],
["/guides/plugins/shopify/billing", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-App-Billing.mdx"))],
["/guides/plugins/shopify/billing", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-App-Billing.mdx"))],
["/guides/plugins/shopify/api-version-changelog", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-Api-Version-Changelog.mdx"))],
["/guides/plugins/shopify/api-version-changelog", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-Api-Version-Changelog.mdx"))],
["/guides/plugins/shopify/polaris", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-Polaris.mdx"))],
["/guides/plugins/shopify/polaris", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-Polaris.mdx"))],
["/guides/plugins/shopify/admin-connection", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-Admin-Connection.mdx"))],
["/guides/plugins/shopify/admin-connection", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/building-shopify-apps/Shopify-Admin-Connection.mdx"))],
["/guides/plugins/openai", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/OpenAI.mdx"))],
["/guides/plugins/openai", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/OpenAI.mdx"))],
["/guides/plugins/sentry", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/Sentry.mdx"))],
["/guides/plugins/sentry", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/Sentry.mdx"))],
["/guides/plugins/bigcommerce", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/BigCommerce.mdx"))],
["/guides/plugins/bigcommerce", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/BigCommerce.mdx"))],
["/guides/plugins/bigcommerce/webhooks", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/bigcommerce/BigCommerce-Webhooks.mdx"))],
["/guides/plugins/bigcommerce/webhooks", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/bigcommerce/BigCommerce-Webhooks.mdx"))],
["/guides/plugins/bigcommerce/data", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/bigcommerce/BigCommerce-Data.mdx"))],
["/guides/plugins/bigcommerce/data", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/bigcommerce/BigCommerce-Data.mdx"))],
["/guides/plugins/bigcommerce/frontends", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/bigcommerce/BigCommerce-Frontends.mdx"))],
["/guides/plugins/bigcommerce/frontends", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/connections/bigcommerce/BigCommerce-Frontends.mdx"))],
["/guides/tutorials/bigcommerce", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/Tutorials-BigCommerce.tsx"))],
["/guides/tutorials/bigcommerce", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/Tutorials-BigCommerce.tsx"))],
["/guides/tutorials/bigcommerce/product-search-keywords", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/bigcommerce-product-search-keywords/BigCommerce-Product-Search-Keywords.mdx"))],
["/guides/tutorials/bigcommerce/product-search-keywords", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/bigcommerce-product-search-keywords/BigCommerce-Product-Search-Keywords.mdx"))],
["/guides/tutorials/automated-product-tagger", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/automated-product-tagger/Automated-Product-Tagger.mdx"))],
["/guides/tutorials/automated-product-tagger", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/automated-product-tagger/Automated-Product-Tagger.mdx"))],
["/guides/tutorials/product-recommendation-quiz-app", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/quiz/Product-Recommendation-Quiz-App.mdx"))],
["/guides/tutorials/product-recommendation-quiz-app", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/quiz/Product-Recommendation-Quiz-App.mdx"))],
["/guides/tutorials/checkout-ui-extension", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/checkout-ui-extension/Checkout-UI-Extension.mdx"))],
["/guides/tutorials/checkout-ui-extension", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/checkout-ui-extension/Checkout-UI-Extension.mdx"))],
["/guides/tutorials/ai-product-recommender", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/ai-product-recommender/AI-Product-Recommender.mdx"))],
["/guides/tutorials/ai-product-recommender", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/ai-product-recommender/AI-Product-Recommender.mdx"))],
["/guides/tutorials/middleware", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/middleware/Middleware.mdx"))],
["/guides/tutorials/middleware", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/middleware/Middleware.mdx"))],
["/guides/tutorials/webhook-subscription", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/webhook-subscription/Webhook-Subscription.mdx"))],
["/guides/tutorials/webhook-subscription", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/webhook-subscription/Webhook-Subscription.mdx"))],
["/guides/tutorials/ai-screenwriter", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/ai-screenwriter/AI-Screenwriter.mdx"))],
["/guides/tutorials/ai-screenwriter", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/ai-screenwriter/AI-Screenwriter.mdx"))],
["/guides/tutorials/web-ai", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/Tutorials-Web-App.tsx"))],
["/guides/tutorials/web-ai", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/Tutorials-Web-App.tsx"))],
["/guides/tutorials/shopify", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/Tutorials-Shopify.tsx"))],
["/guides/tutorials/shopify", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/tutorials/Tutorials-Shopify.tsx"))],
["/guides/actions", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/actions/Actions-overview.mdx"))],
["/guides/actions", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/actions/Actions-overview.mdx"))],
["/guides/actions/types-of-actions", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/actions/Types-of-actions.mdx"))],
["/guides/actions/types-of-actions", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/actions/Types-of-actions.mdx"))],
["/guides/actions/code", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/actions/Action-code.mdx"))],
["/guides/actions/code", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/actions/Action-code.mdx"))],
["/guides/actions/triggers", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/actions/Triggers.mdx"))],
["/guides/actions/triggers", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/actions/Triggers.mdx"))],
["/guides/actions/background", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/actions/Background-actions.mdx"))],
["/guides/actions/background", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/actions/Background-actions.mdx"))],
["/guides/actions/actions-as-code-file", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/actions/Actions-as-code-file-migration.mdx"))],
["/guides/actions/actions-as-code-file", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/actions/Actions-as-code-file-migration.mdx"))],
["/guides/actions/namespaces", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/actions/Action-Namespaces.mdx"))],
["/guides/actions/namespaces", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/actions/Action-Namespaces.mdx"))],
["/guides/glossary", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/Glossary.mdx"))],
["/guides/glossary", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/Glossary.mdx"))],
["/guides/faq", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/FAQ.mdx"))],
["/guides/faq", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/FAQ.mdx"))],
["/guides/models", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/models/Models-overview.mdx"))],
["/guides/models", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/models/Models-overview.mdx"))],
["/guides/models/fields", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/models/Fields.mdx"))],
["/guides/models/fields", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/models/Fields.mdx"))],
["/guides/models/relationships", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/models/Relationships.mdx"))],
["/guides/models/relationships", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/models/Relationships.mdx"))],
["/guides/models/namespaces", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/models/Model-Namespaces.mdx"))],
["/guides/models/namespaces", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/models/Model-Namespaces.mdx"))],
["/guides/http-routes", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/http-routes/HTTP-routes-overview.mdx"))],
["/guides/http-routes", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/http-routes/HTTP-routes-overview.mdx"))],
["/guides/http-routes/route-structure", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/http-routes/Route-structure.mdx"))],
["/guides/http-routes/route-structure", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/http-routes/Route-structure.mdx"))],
["/guides/http-routes/route-configuration", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/http-routes/Route-configuration.mdx"))],
["/guides/http-routes/route-configuration", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/http-routes/Route-configuration.mdx"))],
["/guides/frontend", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/frontend/Frontend-overview.mdx"))],
["/guides/frontend", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/frontend/Frontend-overview.mdx"))],
["/guides/frontend/building-frontends", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/frontend/Building-Frontends.mdx"))],
["/guides/frontend/building-frontends", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/frontend/Building-Frontends.mdx"))],
["/guides/frontend/forms", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/frontend/Frontend-Forms.mdx"))],
["/guides/frontend/forms", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/frontend/Frontend-Forms.mdx"))],
["/guides/frontend/autocomponents", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/frontend/Autocomponents.mdx"))],
["/guides/frontend/autocomponents", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/frontend/Autocomponents.mdx"))],
["/guides/frontend/realtime-queries", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/frontend/Realtime-Queries.mdx"))],
["/guides/frontend/realtime-queries", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/frontend/Realtime-Queries.mdx"))],
["/guides/frontend/remix-in-gadget", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/frontend/Remix-in-Gadget.mdx"))],
["/guides/frontend/remix-in-gadget", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/frontend/Remix-in-Gadget.mdx"))],
["/guides/frontend/external-frontends", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/frontend/External-Frontends.mdx"))],
["/guides/frontend/external-frontends", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/frontend/External-Frontends.mdx"))],
["/guides/frontend/optimize-lcp", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/frontend/Optimize-LCP.mdx"))],
["/guides/frontend/optimize-lcp", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/frontend/Optimize-LCP.mdx"))],
["/guides/development-tools", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/development-tools/Tools-overview.mdx"))],
["/guides/development-tools", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/development-tools/Tools-overview.mdx"))],
["/guides/development-tools/logger", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/development-tools/Logger.mdx"))],
["/guides/development-tools/logger", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/development-tools/Logger.mdx"))],
["/guides/development-tools/cli", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/development-tools/Cli.mdx"))],
["/guides/development-tools/cli", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/development-tools/Cli.mdx"))],
["/guides/development-tools/environment-variables", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/development-tools/Environment-variables.mdx"))],
["/guides/development-tools/environment-variables", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/development-tools/Environment-variables.mdx"))],
["/guides/development-tools/keyboard-shortcuts", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/development-tools/Keyboard-shortcuts.mdx"))],
["/guides/development-tools/keyboard-shortcuts", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/development-tools/Keyboard-shortcuts.mdx"))],
["/guides/development-tools/unit-testing", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/development-tools/Unit-Testing.mdx"))],
["/guides/development-tools/unit-testing", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/development-tools/Unit-Testing.mdx"))],
["/guides/data-access", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/data-access/data-access-overview.mdx"))],
["/guides/data-access", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/data-access/data-access-overview.mdx"))],
["/guides/data-access/api", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/data-access/API-access-to-data.mdx"))],
["/guides/data-access/api", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/data-access/API-access-to-data.mdx"))],
["/guides/development-tools/access-control", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/data-access/Access-Control.mdx"))],
["/guides/development-tools/access-control", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/data-access/Access-Control.mdx"))],
["/guides/data-access/computed-fields", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/data-access/Computed-fields.mdx"))],
["/guides/data-access/computed-fields", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/data-access/Computed-fields.mdx"))],
["/guides/data-access/gelly", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/data-access/Gelly.mdx"))],
["/guides/data-access/gelly", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/data-access/Gelly.mdx"))],
["/guides/account-and-billing", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/Account-and-billing.mdx"))],
["/guides/account-and-billing", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/Account-and-billing.mdx"))],
["/guides/plugins/authentication", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/authentication/Auth-overview.mdx"))],
["/guides/plugins/authentication", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/authentication/Auth-overview.mdx"))],
["/guides/plugins/authentication/helpers", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/authentication/Helpers.mdx"))],
["/guides/plugins/authentication/helpers", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/authentication/Helpers.mdx"))],
["/guides/plugins/authentication/google-oauth", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/authentication/Building-with-Google-Oauth.mdx"))],
["/guides/plugins/authentication/google-oauth", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/authentication/Building-with-Google-Oauth.mdx"))],
["/guides/plugins/authentication/workflows", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/authentication/Auth-flows.mdx"))],
["/guides/plugins/authentication/workflows", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/authentication/Auth-flows.mdx"))],
["/guides/plugins/authentication/email-pass", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/authentication/Building-with-email-pass.mdx"))],
["/guides/plugins/authentication/email-pass", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/authentication/Building-with-email-pass.mdx"))],
["/guides/plugins/authentication/remove-default-auth-methods", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/authentication/Removing-default-auth-methods.mdx"))],
["/guides/plugins/authentication/remove-default-auth-methods", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/authentication/Removing-default-auth-methods.mdx"))],
["/guides/environments", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/environments/Environments.mdx"))],
["/guides/environments", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/environments/Environments.mdx"))],
["/guides/environments/deployment", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/environments/Deployment.mdx"))],
["/guides/environments/deployment", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/environments/Deployment.mdx"))],
["/guides/environments/ci-cd", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/environments/CI-CD.mdx"))],
["/guides/environments/ci-cd", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/environments/CI-CD.mdx"))],
["/guides/source-control", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/source-control/Source-control.mdx"))],
["/guides/source-control", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/source-control/Source-control.mdx"))],
["/guides/gadget-framework/v1-migration", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/v1-migration.mdx"))],
["/guides/gadget-framework/v1-migration", lazy(() => import("/app/packages/docs/pages/guides/3-multi-env/v1-migration.mdx"))],
["/gelly", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/gelly", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/gelly-reference", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/gelly-reference", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/chat", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/chat", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/FAQ", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/FAQ", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/behavior", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/behavior", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/behaviour", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/behaviour", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/external-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/external-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/frontend-deployment", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/frontend-deployment", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/building-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/building-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/quick-start", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/quick-start", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/getting-started/building-apps-with-gadget", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/getting-started/building-apps-with-gadget", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/tutorials/onboarding-middleware", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/tutorials/onboarding-middleware", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/tutorials/post-purchase-upsell", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/tutorials/post-purchase-upsell", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/tutorials/product-bundles", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/tutorials/product-bundles", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/tutorials/beginner", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/tutorials/beginner", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/tutorials/advanced", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/tutorials/advanced", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/tutorials/blog-with-auth", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/tutorials/blog-with-auth", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/tutorials/product-recommendation-quiz-app", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/tutorials/product-recommendation-quiz-app", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/tutorials/web-ai", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/tutorials/web-ai", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/tutorials/ai-screenwriter", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/tutorials/ai-screenwriter", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/tutorials/ai-product-recommender", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/tutorials/ai-product-recommender", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/gelly/reference", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/gelly/reference", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/external-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/external-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/plugins/building-shopify-apps/shopify-app-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/plugins/building-shopify-apps/shopify-app-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/actions/code", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/actions/code", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/connections", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/connections", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/connections/shopify", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/connections/shopify", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/connections/openai", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/connections/openai", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/connections/sentry", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/connections/sentry", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/logging", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/logging", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/cli", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/cli", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/environment-variables", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/environment-variables", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/access-control", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/access-control", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/deploying-to-production", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/deploying-to-production", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/gelly/fragments", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/gelly/fragments", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/gelly/query-structure", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/gelly/query-structure", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/gelly/relational-commands", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/gelly/relational-commands", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/gelly", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/gelly", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/data-aggregation", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/data-aggregation", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/account-and-profile", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/account-and-profile", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/navigating-gadget", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/navigating-gadget", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/usage-and-billing", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/usage-and-billing", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/gelly/fragments", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/gelly/fragments", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/gelly/query-structure", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/gelly/query-structure", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/gelly/relational-commands", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/gelly/relational-commands", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/gelly", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/gelly", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/data-aggregation", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/data-aggregation", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/api/example-app", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/api/example-app", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/extending-with-code", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/extending-with-code", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/shopify", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/shopify", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/syncing-shopify-data", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/syncing-shopify-data", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/shopify-data-security", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/shopify-data-security", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/shopify-app-metafields-metaobjects", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/shopify-app-metafields-metaobjects", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/shopify-app-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/shopify-app-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/shopify-app-oauth", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/shopify-app-oauth", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/shopify-app-billing", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/shopify-app-billing", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/shopify-extensions", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/shopify-extensions", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/shopify-polaris", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/shopify-polaris", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/shopify-api-version-changelog", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-shopify-apps/shopify-api-version-changelog", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/openai", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/openai", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-ai-apps", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/building-ai-apps", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/sentry", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/sentry", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/authentication", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/authentication", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/authentication/helpers", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/authentication/helpers", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/authentication/workflows", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/authentication/workflows", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/authentication/google-oauth", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/authentication/google-oauth", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/tutorials/connecting-to-shopify", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/tutorials/connecting-to-shopify", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/example/authentication", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/example/authentication", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/building-models", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/building-models", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/shopify-app-billing", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/shopify-app-billing", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/shopify-app-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/shopify-app-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/shopify-app-oauth", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/shopify-app-oauth", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/shopify-cli-external-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/shopify-cli-external-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/shopify-polaris", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/connections/shopify-polaris", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/first-deployment", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/first-deployment", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/frontend-deployment/heroku", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/frontend-deployment/heroku", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/frontend-deployment/vercel", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/frontend-deployment/vercel", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/frontend-deployment/netlify", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/frontend-deployment/netlify", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/frontend/external-deployment/heroku", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/frontend/external-deployment/heroku", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/frontend/external-deployment/vercel", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/frontend/external-deployment/vercel", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/frontend/external-deployment/netlify", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/frontend/external-deployment/netlify", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/gadget-vs-x", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/gadget-vs-x", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/tutorials/blog-with-publish", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/tutorials/blog-with-publish", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/building-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/building-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/building-models", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/building-models", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/connections/shopify-app-oauth", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/connections/shopify-app-oauth", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/connections/shopify-cli-external-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/connections/shopify-cli-external-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/connections/shopify-polaris", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/connections/shopify-polaris", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/frontend/external-deployment/heroku", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/frontend/external-deployment/heroku", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/frontend/external-deployment/netlify", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/frontend/external-deployment/netlify", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/frontend/external-deployment/vercel", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/frontend/external-deployment/vercel", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/frontend/external-deployment", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/frontend/external-deployment", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/plugins/shopify-api-version-changelog", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/plugins/shopify-api-version-changelog", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/plugins/shopify-app-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/plugins/shopify-app-frontends", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/plugins/shopify-app-oauth", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/plugins/shopify-app-oauth", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/plugins/shopify-polaris", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/versions/effects/guides/plugins/shopify-polaris", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/deployment", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/guides/deployment", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/reference", lazy(() => import("/app/packages/docs/pages/reference/Reference.tsx"))],
["/reference", lazy(() => import("/app/packages/docs/pages/reference/Reference.tsx"))],
["/reference/gadget-server", lazy(() => import("/app/packages/docs/pages/reference/gadget-server.mdx"))],
["/reference/gadget-server", lazy(() => import("/app/packages/docs/pages/reference/gadget-server.mdx"))],
["/reference/react", lazy(() => import("/app/packages/docs/pages/reference/@gadgetinc-react.mdx"))],
["/reference/react", lazy(() => import("/app/packages/docs/pages/reference/@gadgetinc-react.mdx"))],
["/reference/react/auto", lazy(() => import("/app/packages/docs/pages/reference/@gadgetinc-react-auto.mdx"))],
["/reference/react/auto", lazy(() => import("/app/packages/docs/pages/reference/@gadgetinc-react-auto.mdx"))],
["/reference/auth", lazy(() => import("/app/packages/docs/pages/reference/@gadgetinc-auth.mdx"))],
["/reference/auth", lazy(() => import("/app/packages/docs/pages/reference/@gadgetinc-auth.mdx"))],
["/reference/gelly", lazy(() => import("/app/packages/docs/pages/reference/Gelly.mdx"))],
["/reference/gelly", lazy(() => import("/app/packages/docs/pages/reference/Gelly.mdx"))],
["/reference/ggt", lazy(() => import("/app/packages/docs/pages/reference/ggt.mdx"))],
["/reference/ggt", lazy(() => import("/app/packages/docs/pages/reference/ggt.mdx"))],
["/reference/shopify-app-bridge", lazy(() => import("/app/packages/docs/pages/reference/Shopify-bridge.mdx"))],
["/reference/shopify-app-bridge", lazy(() => import("/app/packages/docs/pages/reference/Shopify-bridge.mdx"))],
["/reference/shopify-extensions", lazy(() => import("/app/packages/docs/pages/reference/@gadgetinc-shopify-extensions.mdx"))],
["/reference/shopify-extensions", lazy(() => import("/app/packages/docs/pages/reference/@gadgetinc-shopify-extensions.mdx"))],
["/reference/react-bigcommerce", lazy(() => import("/app/packages/docs/pages/reference/@gadgetinc-react-bigcommerce.mdx"))],
["/reference/react-bigcommerce", lazy(() => import("/app/packages/docs/pages/reference/@gadgetinc-react-bigcommerce.mdx"))],
["/auth/new-app/summon", lazy(() => import("/app/packages/web/src/components/auth/SummonAppPage.tsx"))],
["/auth/new-app/summon", lazy(() => import("/app/packages/web/src/components/auth/SummonAppPage.tsx"))],
["/auth/team/billing", lazy(() => import("/app/packages/web/src/components/auth/TeamBilling.tsx"))],
["/auth/team/billing", lazy(() => import("/app/packages/web/src/components/auth/TeamBilling.tsx"))],
["/auth/team/members", lazy(() => import("/app/packages/web/src/components/auth/TeamMembersPage.tsx"))],
["/auth/team/members", lazy(() => import("/app/packages/web/src/components/auth/TeamMembersPage.tsx"))],
["/auth/team/new", lazy(() => import("/app/packages/web/src/components/auth/NewTeam.tsx"))],
["/auth/team/new", lazy(() => import("/app/packages/web/src/components/auth/NewTeam.tsx"))],
["/auth/team", lazy(() => import("/app/packages/web/src/components/auth/TeamPage.tsx"))],
["/auth/team", lazy(() => import("/app/packages/web/src/components/auth/TeamPage.tsx"))],
["/auth/cli", lazy(() => import("/app/packages/web/src/components/auth/CliPage.tsx"))],
["/auth/cli", lazy(() => import("/app/packages/web/src/components/auth/CliPage.tsx"))],
["/auth/confirm-email", lazy(() => import("/app/packages/web/src/components/auth/EmailConfirmationPage.tsx"))],
["/auth/confirm-email", lazy(() => import("/app/packages/web/src/components/auth/EmailConfirmationPage.tsx"))],
["/auth/invitation", lazy(() => import("/app/packages/web/src/components/auth/InvitationErrorPage.tsx"))],
["/auth/invitation", lazy(() => import("/app/packages/web/src/components/auth/InvitationErrorPage.tsx"))],
["/auth/login", lazy(() => import("/app/packages/web/src/components/auth/LoginPage.tsx"))],
["/auth/login", lazy(() => import("/app/packages/web/src/components/auth/LoginPage.tsx"))],
["/auth/new-app", lazy(() => import("/app/packages/web/src/components/auth/NewAppPage.tsx"))],
["/auth/new-app", lazy(() => import("/app/packages/web/src/components/auth/NewAppPage.tsx"))],
["/auth/reset", lazy(() => import("/app/packages/web/src/components/auth/ForgotPasswordPage.tsx"))],
["/auth/reset", lazy(() => import("/app/packages/web/src/components/auth/ForgotPasswordPage.tsx"))],
["/auth/send-verification", lazy(() => import("/app/packages/web/src/components/auth/LoginPage.tsx"))],
["/auth/send-verification", lazy(() => import("/app/packages/web/src/components/auth/LoginPage.tsx"))],
["/auth/signup", lazy(() => import("/app/packages/web/src/components/auth/LoginPage.tsx"))],
["/auth/signup", lazy(() => import("/app/packages/web/src/components/auth/LoginPage.tsx"))],
["/api/:slug/:environmentSlug", lazy(() => import("/app/packages/docs/pages/api-reference/API-reference.tsx"))],
["/api/:slug/:environmentSlug", lazy(() => import("/app/packages/docs/pages/api-reference/API-reference.tsx"))],
["/api/:slug/:environmentSlug/template-architecture", lazy(() => import("/app/packages/docs/pages/app-templates/App-template-architecture.tsx"))],
["/api/:slug/:environmentSlug/template-architecture", lazy(() => import("/app/packages/docs/pages/app-templates/App-template-architecture.tsx"))],
["/api/:slug/:environmentSlug/template-setup", lazy(() => import("/app/packages/docs/pages/app-templates/App-template-setup.tsx"))],
["/api/:slug/:environmentSlug/template-setup", lazy(() => import("/app/packages/docs/pages/app-templates/App-template-setup.tsx"))],
["/api/:slug/:environmentSlug/external-api-calls/installing", lazy(() => import("/app/packages/docs/pages/api-reference/Installing.mdx"))],
["/api/:slug/:environmentSlug/external-api-calls/installing", lazy(() => import("/app/packages/docs/pages/api-reference/Installing.mdx"))],
["/api/:slug/:environmentSlug/external-api-calls/authentication", lazy(() => import("/app/packages/docs/pages/api-reference/Authentication.mdx"))],
["/api/:slug/:environmentSlug/external-api-calls/authentication", lazy(() => import("/app/packages/docs/pages/api-reference/Authentication.mdx"))],
["/api/:slug/:environmentSlug/external-api-calls", lazy(() => import("/app/packages/docs/pages/api-reference/External-API-calls-with-Gadget.mdx"))],
["/api/:slug/:environmentSlug/external-api-calls", lazy(() => import("/app/packages/docs/pages/api-reference/External-API-calls-with-Gadget.mdx"))],
["/api/:slug/:environmentSlug/api-calls-within-gadget", lazy(() => import("/app/packages/docs/pages/api-reference/API-calls-within-Gadget.mdx"))],
["/api/:slug/:environmentSlug/api-calls-within-gadget", lazy(() => import("/app/packages/docs/pages/api-reference/API-calls-within-Gadget.mdx"))],
["/api/:slug/:environmentSlug/errors", lazy(() => import("/app/packages/docs/pages/api-reference/Errors.mdx"))],
["/api/:slug/:environmentSlug/errors", lazy(() => import("/app/packages/docs/pages/api-reference/Errors.mdx"))],
["/api/:slug/:environmentSlug/schema/global-actions", lazy(() => import("/app/packages/docs/pages/api-reference/Global-Actions.mdx"))],
["/api/:slug/:environmentSlug/schema/global-actions", lazy(() => import("/app/packages/docs/pages/api-reference/Global-Actions.mdx"))],
["/api/:slug/:environmentSlug/external-api-calls/graphql", lazy(() => import("/app/packages/docs/pages/api-reference/GraphQL.mdx"))],
["/api/:slug/:environmentSlug/external-api-calls/graphql", lazy(() => import("/app/packages/docs/pages/api-reference/GraphQL.mdx"))],
["/api/:slug/:environmentSlug/internal-api", lazy(() => import("/app/packages/docs/pages/api-reference/Internal-API.mdx"))],
["/api/:slug/:environmentSlug/internal-api", lazy(() => import("/app/packages/docs/pages/api-reference/Internal-API.mdx"))],
["/api/:slug/:environmentSlug/schema/:model", lazy(() => import("/app/packages/docs/src/ModelPagePicker.tsx"))],
["/api/:slug/:environmentSlug/schema/:model", lazy(() => import("/app/packages/docs/src/ModelPagePicker.tsx"))],
["/api/:slug/:environmentSlug/using-with-react", lazy(() => import("/app/packages/docs/pages/api-reference/Using-With-React.mdx"))],
["/api/:slug/:environmentSlug/using-with-react", lazy(() => import("/app/packages/docs/pages/api-reference/Using-With-React.mdx"))],
["/api/:slug/:environmentSlug/gadget-record", lazy(() => import("/app/packages/docs/pages/api-reference/Gadget-Record.mdx"))],
["/api/:slug/:environmentSlug/gadget-record", lazy(() => import("/app/packages/docs/pages/api-reference/Gadget-Record.mdx"))],
["/api/:slug/:environmentSlug/sorting-and-filtering", lazy(() => import("/app/packages/docs/pages/api-reference/Sorting-and-Filtering.mdx"))],
["/api/:slug/:environmentSlug/sorting-and-filtering", lazy(() => import("/app/packages/docs/pages/api-reference/Sorting-and-Filtering.mdx"))],
["/api/:slug/template-architecture", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/template-architecture", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/external-api-calls/installing", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/external-api-calls/installing", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/external-api-calls/authentication", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/external-api-calls/authentication", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/external-api-calls", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/external-api-calls", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/api-calls-within-gadget", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/api-calls-within-gadget", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/errors", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/errors", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/schema/global-actions", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/schema/global-actions", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/external-api-calls/graphql", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/external-api-calls/graphql", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/internal-api", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/internal-api", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/using-with-react", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/using-with-react", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/gadget-record", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/gadget-record", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/sorting-and-filtering", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/sorting-and-filtering", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/actions", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/actions", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/authentication", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/authentication", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/development/authentication", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/development/authentication", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/development/graphql", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/development/graphql", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/development/installing", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/development/installing", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/development/public-embedded-shopify-apps", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/development/public-embedded-shopify-apps", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/development/type-safety", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/development/type-safety", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/example-app-dev", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/example-app-dev", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/public-embedded-shopify-apps", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/public-embedded-shopify-apps", lazy(() => import("/app/packages/docs/src/components/Redirect.tsx"))],
["/api/:slug/:environmentSlug/schema/:firstNamespaceSegment/:splat*", lazy(() => import("/app/packages/docs/src/ModelPagePicker.tsx"))],
["/api/:slug/:environmentSlug/schema/:firstNamespaceSegment/:splat*", lazy(() => import("/app/packages/docs/src/ModelPagePicker.tsx"))],
["/:splat*", lazy(() => import("/app/packages/docs/pages/NotFound.tsx"))],
["/:splat*", lazy(() => import("/app/packages/docs/pages/NotFound.tsx"))]
  ]
          